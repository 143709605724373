import React from 'react';
import style from '../../style/components/signin/RequestDataButton.module.scss';
import { useSelector } from 'react-redux';
import { MMDDYYYY } from '../../utils/time';

const RequestDataButton = ({ setIsRequestDataModalOpen }) => {
  const dataDownloads = useSelector((state) => state.dataDownloads);

  if (dataDownloads.isFetching) {
    return (
      <idme-button
        variant="secondary"
        text="Loading..."
        type="button"
        size="small"
        disabled={true}
      />
    );
  }

  if (!dataDownloads.available) {
    return (
      <div className={style.downloadStatus}>
        <div className={style.statusRow}>
          <div className={style.statusColumn}>
            <strong>Last download: </strong>
          </div>
          <div className={style.statusColumn}>
            {MMDDYYYY(dataDownloads.last_request_date)}
          </div>
        </div>
        <div className={style.statusRow}>
          <div className={style.statusColumn}>
            <strong>Next available: </strong>
          </div>
          <div className={style.statusColumn}>
            {MMDDYYYY(dataDownloads.available_date)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <idme-button
      variant="secondary"
      text="Request my data"
      type="button"
      size="small"
      disabled
      onClick={() => setIsRequestDataModalOpen(true)}
    />
  );
};

export default RequestDataButton;
